
export default () => {
  const selectedVariant = useState('productSelectedVariant', () => {})
  const openProductModal = useState("openProductModal", () => false)
  const selectedImage = useState("selectedImage", () => "")
  const sortedImages = useState("sortedImages", () => [])

  const setSelectedImage = (image) => {
    selectedImage.value = image
  }

  const setSortedImages = (images) => {
    sortedImages.value = images
  }

  /**
   * Bu fonksiyonda modal olarak açılan ürün görselinin, tıklanan görselden başlayarak açılması için sıralama işlemi yapılıyor.
   * @returns {Promise<void>}
   */
  const changeImages = async () => {

    if (typeof selectedVariant === 'undefined') {
      return;
    }

    const indexImage = selectedVariant.value.images.findIndex(
      (image) => image.original === selectedImage.value
    )

    const lastImages = selectedVariant.value?.images.slice(indexImage)
    const beforeImages = selectedVariant.value?.images.slice(0, indexImage)
    setSortedImages([...lastImages, ...beforeImages])
  }

  watch([selectedVariant, selectedImage], (val) => {
    changeImages()
  })

  return {
    openProductModal,
    selectedImage,
    sortedImages,
    setSelectedImage,
    setSortedImages,
    changeImages
  }
}
